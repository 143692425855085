import logo from './logo.svg';
import './App.css';
import Header from "./components/Header"
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LocationsCard from "./components/LocationsCard";
function App() {
  return (
    <div className="wrapper" >
      {/*<Container fluid className="header-cont">*/}
      {/*  <Row>*/}
      {/*<Header/>*/}
      {/*</Row>*/}
      {/*</Container>*/}
      {/*<div className="Label">Наші локації:</div>*/}
      <div className= "location-container">
        <Row>
        <LocationsCard/>
        </Row>
      </div>
    </div>
  );
}

export default App;
