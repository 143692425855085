import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import './index.css';
import CircleIcon from '@mui/icons-material/Circle';
import NearMeIcon from '@mui/icons-material/NearMe';
import Button from '@mui/material/Button';

import request from "../../requests";

export default function LocationsCard() {
    const SitePrefix = "https://vinyltogo.com.ua/collections/"
    const [locations, setLocations] = useState([])
    useEffect(() => {
        request.list().then(response => {
            console.log(response)
            setLocations(response.data.result)
        })
    }, [])

    const geoCodingService = () => {

    }

    const LocationCard = ({Locations}) => {
        const isProductLocations = Locations.filter(value => value.count > 0)
        const sortedLocations = isProductLocations.sort((a, b) => a.count < b.count)
        return (
        <Container>
            {sortedLocations.map(loc => (
                <Container fluid className="location-card">
                    <a style={{textDecoration: 'none', color: "black"}} href={`${SitePrefix}${loc.qr}`} target="_blank"
                       rel="noreferrer">
                        <Row className="location-card-row">
                            <div>
                                <div className="Location" key={loc.id}>Локація: <b>{loc.name}</b></div>
                                <div className="Address" key={loc.id}>Адреса: {loc.address}</div>
                                <div className="Address Geo"><NearMeIcon/> 2km</div>
                            </div>
                            <div>
                                <p className="helper-text">Клікніть на картку, щоб перейти до локації</p>
                                <p><b>Наявність: <CircleIcon
                                    style={{color: loc.count > 0 ? "#58cb39" : "62D2A2"}}/> {loc.count}
                                </b></p>

                            </div>
                        </Row>
                    </a>
                </Container>
            ))}
        </Container>)

    };
    return <LocationCard Locations={locations}/>

}
