import axios from "axios";
const axiosInstance = axios.create({
  baseURL: "https://www.vinyl-cafe.com.ua/",
  timeout: 30000,
});
const prefix = "/openapi/"

const request ={
  list: async () => {
    try {
      const response = await axiosInstance.get(prefix + "shop/listLocations" );
      return response;
    } catch (error) {
      return error;
    }
  }
}

export default request;
